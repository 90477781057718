import { LoginService } from './../../../core/services/login.service';
import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit, AfterViewChecked {

  hideSidebar = false;
  isSmartphone = false;
  collapseLinks = false;

  constructor(
    private route: Router,
    private cdk: ChangeDetectorRef,
    private loginService: LoginService
  ) {
  }

  ngOnInit(): void {
    this.checkWindowSize();
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
    this.cdk.detectChanges();
  }

  checkWindowSize(): void {
    const screenWidth = document.documentElement.clientWidth;
    this.hideSidebar = screenWidth < 1080;
    this.isSmartphone = screenWidth < 1080;
  }

  checkCollapse(): void {
    this.collapseLinks = false;
  }

  logout(): void {
    this.loginService.logout()
  }
}
