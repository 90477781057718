import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './core/guards/login.guard';
import { LoginComponent } from './core/login/login.component';
import { PasswordRecoverComponent } from './core/password-recover/password-recover.component';
import { PrivacyPolicyComponent } from './core/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './core/terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'recuperar-senha', component: PasswordRecoverComponent, canActivate: [LoginGuard] },
  { path: 'termos-de-uso', component: TermsOfUseComponent },
  { path: 'politicas-de-privacidade', component: PrivacyPolicyComponent },

  { path: 'admin', loadChildren: () => import('./adm/adm.module').then((m) => m.AdmModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
