import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {patterns} from '../../shared/helpers/patterns.helper';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {
  forgotPasswordForm: FormGroup
  forgotTokenForm: FormGroup
  forgot: boolean = false

  sendToken: boolean = false

  qttForRequisition: number = 1

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.forgotTokenForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      token: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
    })
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  submitForgot() {
    if (this.forgotPasswordForm.valid) {
      this.spinner.show()
      this.loginService.forgotPassword(this.forgotPasswordForm.value).subscribe(response => {
        this.spinner.hide()
        this.sendToken = true
        this.forgotTokenForm.get('email')?.patchValue(this.forgotPasswordForm.get('email')?.value)
        this.toastr.success('Senha enviada para seu e-mail', 'Sucesso!')
      }, error => {
        this.spinner.hide()
        this.toastr.error(`E-mail não encontrado`)
        this.forgotPasswordForm.reset()
      })
    } else {
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  submitToken() {
    if (this.forgotTokenForm.valid && this.equalsForm) {
      this.spinner.show()
      this.loginService.forgotPasswordToken(this.forgotTokenForm.value).subscribe(response => {
        this.spinner.hide()
        this.toastr.success('Senha Alterada !', 'Sucesso!')
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 3000);
      }, error => {
        this.spinner.hide()
        if (this.qttForRequisition == 4) {
          this.sendToken = false
          this.toastr.error('Quantidade de tentativas excedida!', 'Tente novamente !')
          this.router.navigate(['/login'])
        }
        this.qttForRequisition++
        this.toastr.error('Verifique se o Token ou se as senhas estão corretas', 'Error!')
        this.forgotTokenForm.get('token')?.reset()
        this.forgotTokenForm.get('password')?.reset()
        this.forgotTokenForm.get('password_confirmation')?.reset()
      })
    } else {
      this.toastr.error('Senha deve ter mínimo de 6 caracteres')
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  get emailForgot() {
    return this.forgotPasswordForm.get('email')
  }

  get equalsForm() {
    return this.forgotTokenForm.get('password')?.value == this.forgotTokenForm.get('password_confirmation')?.value
  }

}
