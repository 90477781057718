<div class="container-fluid d-flex justify-content-center align-items-center">
  <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login form-row">

    <div class="card" style="width: 50rem;">
      <div class="card-body">
        <div class="col-12 d-flex justify-content-center logo">
          <img src="../../../assets/img/png/logoExterna.png" alt="">
        </div>
        <div class="d-flex justify-content-center logo">
          <p>Gerencial</p>
        </div>
        <div>
          <h5>Olá, seja bem-vindo(a) ao nosso portal gerencial</h5>
          <p>Para acessar o portal, por favor insira suas credenciais abaixo:</p>
        </div>
        <div class="col-12 form-group">
          <input type="email" placeholder="E-mail" formControlName="email" id="email" class="form-control">
        </div>
        <div class="col-12 form-group">
          <input type="password" placeholder="Senha" formControlName="password" id="password" class="form-control">
        </div>
        <div class="col-12 esquece">
          <a routerLink="/recuperar-senha">Esqueci minha senha</a>
        </div>
        <div class="col-12 form-group button">
        <button type="submit" class="btn btn-warning btn-sm btn-block">Acessar</button>
        </div>
        <div class="col-12 mt-4 term">
          <a routerLink="/termos-de-uso">Termos de Uso</a><br>
          <a routerLink="/politicas-de-privacidade">Políticas de Privacidade</a>
        </div>
      </div>
    </div>
  </form>
</div>
