<div (window:resize)="checkWindowSize()" [class.smartphone]="isSmartphone" class="wrapper">
  <nav class="sidebar" [class.hide-sidebar]="hideSidebar">
    <div class="row m-0">
      <div class="col-12 nav-header mb-4 mt-4 ml-4">
        <img src="../../../../assets/img/svg/logo-horizontal.svg" alt="ZippZaap">
      </div>
      <div class="row w-100 m-0 only-nav">
        <ul class="menu">
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./usuarios">
              <div class="section-name">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 10.382 12.016">
                  <defs>
                    <style>.user {
                      fill: #fff;
                      stroke: none!important;
                    }</style>
                  </defs>
                  <g transform="translate(-34.007)">
                    <g transform="translate(36.85)">
                      <g transform="translate(0)">
                        <path class="user"
                              d="M154.652,0A2.351,2.351,0,0,0,152.3,2.348V3.565a2.348,2.348,0,1,0,4.7,0V2.348A2.35,2.35,0,0,0,154.652,0Z"
                              transform="translate(-152.304)"/>
                      </g>
                    </g>
                    <g transform="translate(34.007 6.294)">
                      <g transform="translate(0)">
                        <path class="user"
                              d="M44.376,264.575a5.955,5.955,0,0,0-3.384-2.675.094.094,0,0,0-.094.023,9.8,9.8,0,0,1-1.7,1.291,12.2,12.2,0,0,1-1.7-1.291.094.094,0,0,0-.094-.023,5.954,5.954,0,0,0-3.384,2.675.094.094,0,0,0,0,.1,6.021,6.021,0,0,0,10.356,0A.094.094,0,0,0,44.376,264.575Z"
                              transform="translate(-34.007 -261.896)"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Usuários</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./noticias">
              <div class="section-name">
                <svg xmlns="../../../../assets/img/svg/iconNoticia.svg" width="14" height="16" viewBox="0 0 11.381 7.588">
                  <defs>
                    <style>.news {
                      fill: #fff;
                      stroke: none!important;
                    }</style>
                  </defs>
                  <path class="news"
                        d="M10.907,4.5H1.739a.474.474,0,0,0-.474.474v.158H.474A.474.474,0,0,0,0,5.607v5.375a1.107,1.107,0,0,0,1.107,1.107h9.326a.948.948,0,0,0,.948-.948V4.974A.474.474,0,0,0,10.907,4.5Zm-9.8,6.639a.158.158,0,0,1-.158-.158v-4.9h.316v4.9A.158.158,0,0,1,1.107,11.139Zm4.663-.316h-3a.237.237,0,0,1-.237-.237v-.158a.237.237,0,0,1,.237-.237h3a.237.237,0,0,1,.237.237v.158A.237.237,0,0,1,5.77,10.823Zm4.11,0h-3a.237.237,0,0,1-.237-.237v-.158a.237.237,0,0,1,.237-.237h3a.237.237,0,0,1,.237.237v.158A.237.237,0,0,1,9.88,10.823Zm-4.11-1.9h-3a.237.237,0,0,1-.237-.237V8.531a.237.237,0,0,1,.237-.237h3a.237.237,0,0,1,.237.237v.158A.237.237,0,0,1,5.77,8.926Zm4.11,0h-3a.237.237,0,0,1-.237-.237V8.531a.237.237,0,0,1,.237-.237h3a.237.237,0,0,1,.237.237v.158A.237.237,0,0,1,9.88,8.926Zm0-1.9H2.766a.237.237,0,0,1-.237-.237V6a.237.237,0,0,1,.237-.237H9.88A.237.237,0,0,1,10.117,6v.79A.237.237,0,0,1,9.88,7.029Z"
                        transform="translate(0 -4.5)"/>
                </svg>
                <span>Notícias</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./courses">
              <div class="section-name">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Retângulo_2641" data-name="Retângulo 2641" width="16" height="16" fill="#fff"/>
                    </clipPath>
                      <style>.product {
                        fill: #fff;
                        stroke: none!important;
                      }</style>
                  </defs>
                  <g id="Grupo_2318" data-name="Grupo 2318" clip-path="url(#clip-path)">
                    <path id="Caminho_1992" data-name="Caminho 1992" d="M14.6,6.643,8.356.4A.938.938,0,1,0,7.029,1.728L13.27,7.969A.937.937,0,0,0,14.6,6.643Z" fill="#fff"/>
                    <path id="Caminho_1993" data-name="Caminho 1993" d="M6.566,2.591l-.047.235A10.077,10.077,0,0,1,4.3,7.328l3.4,3.4a10.043,10.043,0,0,1,4.48-2.244l.236-.047Z" fill="#fff"/>
                    <path id="Caminho_1994" data-name="Caminho 1994" d="M3.674,8.031l-2.32,2.32a1.406,1.406,0,0,0,0,1.989L2.68,13.666a1.406,1.406,0,0,0,1.989,0l2.32-2.32Zm.331,3.646a.469.469,0,1,1-.663-.663l.663-.663a.469.469,0,0,1,.663.663Z" fill="#fff"/>
                    <path id="Caminho_1995" data-name="Caminho 1995" d="M8.946,13.915l.638-.638a1.405,1.405,0,0,0,0-1.988l-.474-.474a8.886,8.886,0,0,0-.755.571l.566.566a.468.468,0,0,1,0,.662l-.648.648L7.32,12.34,5.994,13.666l2.127,2.061A.938.938,0,0,0,9.446,14.4Z" fill="#fff"/>
                    <path id="Caminho_1996" data-name="Caminho 1996" d="M10.84,0a.468.468,0,0,0-.469.469v.937a.469.469,0,1,0,.938,0V.469A.469.469,0,0,0,10.84,0Z" fill="#fff"/>
                    <path id="Caminho_1997" data-name="Caminho 1997" d="M14.59,3.75h-.937a.469.469,0,0,0,0,.938h.938a.469.469,0,1,0,0-.937Z" fill="#fff"/>
                    <path id="Caminho_1998" data-name="Caminho 1998" d="M13.983,1.075a.469.469,0,0,0-.663,0l-.937.938a.469.469,0,0,0,.663.663l.938-.937A.469.469,0,0,0,13.983,1.075Z" fill="#fff"/>
                  </g>
                </svg>

                <span>Cursos</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./forum">
              <i class=""></i>
              <div class="section-name">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.152" viewBox="0 0 14 13.152">
                  <g id="Grupo_2317" data-name="Grupo 2317" transform="translate(302 -1571)">
                    <path id="Caminho_1986" data-name="Caminho 1986" d="M70,5.936l3,1.2,3-1.2-3-1.2Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1987" data-name="Caminho 1987" d="M70.949,7.2v.947L73,8.964l2.051-.82V7.2L73,8.018Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1988" data-name="Caminho 1988" d="M66,1.424V12.088H80V1.424Zm11.512,8.2h-.82V6.541l-.82.328V8.7L73,9.848,70.129,8.7V6.869l-2.335-.934L73,3.853l4.512,1.8Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1989" data-name="Caminho 1989" d="M74.663,12.936H71.337l-.273.82H69.309v.82h7.383v-.82H74.936Z" transform="translate(-368 1569.576)" fill="#fff"/>
                  </g>
                </svg>
                <span>Fórum</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./configuracao">
              <i class=""></i>
              <div class="section-name">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.152" viewBox="0 0 14 13.152">
                  <g id="Grupo_2317" data-name="Grupo 2317" transform="translate(302 -1571)">
                    <path id="Caminho_1986" data-name="Caminho 1986" d="M70,5.936l3,1.2,3-1.2-3-1.2Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1987" data-name="Caminho 1987" d="M70.949,7.2v.947L73,8.964l2.051-.82V7.2L73,8.018Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1988" data-name="Caminho 1988" d="M66,1.424V12.088H80V1.424Zm11.512,8.2h-.82V6.541l-.82.328V8.7L73,9.848,70.129,8.7V6.869l-2.335-.934L73,3.853l4.512,1.8Z" transform="translate(-368 1569.576)" fill="#fff"/>
                    <path id="Caminho_1989" data-name="Caminho 1989" d="M74.663,12.936H71.337l-.273.82H69.309v.82h7.383v-.82H74.936Z" transform="translate(-368 1569.576)" fill="#fff"/>
                  </g>
                </svg>
                <span>Configuração</span>
              </div>
            </a>
          </li>
          <li (click)="logout()">
            <a>
              <div class="section-name">
                <svg xmlns="../../../../assets/img/svg/iconSair.svg" width="14" height="16" viewBox="0 0 11.525 11.525">
                  <defs>
                    <style>.a, .b {
                      fill: #fff;
                    }

                    .b {
                      stroke: #fff;
                      stroke-width: 0;
                    }</style>
                  </defs>
                  <path class="a"
                        d="M5.763,0a5.763,5.763,0,1,0,5.763,5.763A5.769,5.769,0,0,0,5.763,0Zm0,10.478a4.715,4.715,0,1,1,4.715-4.715A4.72,4.72,0,0,1,5.763,10.478Z"/>
                  <path class="b" d="M1.954,0,1.6.355,2.944,1.7H0v.508H2.944L1.6,3.553l.355.355L3.909,1.954Z"
                        transform="translate(4 3.62)"/>
                </svg>
                <span>SAIR</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div [class.hide-sidebar]="hideSidebar" class="content">
    <div class="container-fluid min-vh-100 position-relative">
      <button (click)="hideSidebar = !hideSidebar" [class.d-none]="!isSmartphone" class="btn toggle">
        =
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
