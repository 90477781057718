import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/app.api';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class TextsService extends BaseService {

  urlService(){
    return ''
  }

  getTermsOfUse(): Observable<any>{
    return this.http.get(`${API}/terms-use`)
  }

  postTermsOfUse(body): Observable<any>{
    return this.http.post(`${API}/terms-use`, body)
  }

  getPrivacyPolicy(): Observable<any>{
    return this.http.get(`${API}/privacy-policies`)
  }

  postPrivacyPolicy(body): Observable<any>{
    return this.http.post(`${API}/privacy-policies`, body)
  }
}
