<div class="container-fluid d-flex justify-content-center align-items-center">

    <div class="card" style="width: 50rem;">

      <div class="card-body">
        <div class="col-12 d-flex justify-content-center mb-4 logo">
          <img src="../../../assets/img/png/logoExterna.png" alt="">
        </div>
        <div>
          <h5 class="bold mb-3">Recuperação de senha</h5>
          <p>Enviaremos um código de recuperação para
            o seu email ex***@mail.com
            <br>Por favor preencha o endereço:</p>
        </div>
        <ng-container *ngIf="!sendToken">
          <form [formGroup]="forgotPasswordForm" (submit)="submitForgot()">
            <div class="mt-3">
              <input formControlName="email" type="email" class="form-control w-100" placeholder="E-mail">
              <button type="submit" class="btn btn-warning btn-sm btn-block my-4">Enviar</button>
            </div>
          </form>
          </ng-container>
          <ng-container *ngIf="sendToken">
            <form [formGroup]="forgotTokenForm" (ngSubmit)="submitToken()">
              <div class="mt-3 p-3">
                <input formControlName="email" type="email" class="form-control w-100" [attr.disabled]="true" placeholder="E-mail">
                <input formControlName="token" type="text" class="form-control w-100 mt-3" placeholder="Token">
                <input formControlName="password" type="password" class="form-control w-100 mt-3" placeholder="Nova Senha">
                <input formControlName="password_confirmation" type="password" class="form-control w-100 mt-3" placeholder="Confirmar Nova Senha">
                <button type="submit" class="btn btn-warning btn-sm btn-block my-4">Enviar</button>
              </div>
            </form>
          </ng-container>
        <div class="col-12 mb-2">
          <a routerLink="/login">&#60; VOLTAR</a>
        </div>
      </div>
    </div>
</div>

