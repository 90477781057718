<nav class="navbar navbar-light cor-padrão">
  <div class="col-12 d-flex justify-content-center logo">
    <img src="../../../assets/img/png/logoInterna.png" alt="">
  </div>
</nav>

<div class="d-flex align-items-center">
  <div class="pt-3 pb-3 space" style="
  background: white;">
    <div class="row">
      <div class="col-12 mr-6">
        <h2>Política de Privacidade</h2>
        <br>
        <p [innerHTML]="privacy?.content"> </p>
        <!-- <ul>
          <p>
            O ZIPPZAPP acredita que você deva sempre saber quais dados coletamos de você e como os utilizamos. Nossa política de privacidade serve para dar total controle a você, bem como torná-lo capaz de tomar as decisões sobre o que você compartilha conosco.
          </p>
        <li><strong class="pt-2 pb-4">
            Informações básicas da conta
          </strong><br><br>
        </li>
          <p>
            Nós requeremos determinadas informações para fornecer nossos serviços para você. Por exemplo, você precisa ter uma conta para visualizar ou compartilhar conteúdo no ZippZapp. Quando você opta por compartilhar as informações abaixo conosco, nós as coletamos e usamos para operar nossos serviços.
          </p>
        <li> <strong class="pt-2 pb-4">
            Informações públicas
          </strong><br><br></li>
          <p>
            A maioria das atividades no ZippZapp são públicas, inclusive informações de perfil como: nome, nome de usuário, instituição de ensino, área de atuação, publicações no fórum ou vídeo aulas publicadas.
          </p>
          <p>
            Ao compartilhar publicamente um conteúdo, você está nos instruindo a divulgar essas informações da maneira mais ampla possível, inclusive por meio de nossas APIs, e direcionando àqueles que acessam as informações por meio de nossas APIs a fazer o mesmo.
          </p>
        <li><strong class="pt-2 pb-4">
            Informações de Contato e Catálogos de Endereços
          </strong><br><br></li>
          <p>
            Usamos suas informações de contato, como seu endereço de e-mail ou número de telefone, para autenticar sua conta e mantê-la, usamos, também, para manter os nossos serviços em segurança e para ajudar a prevenir spam, fraude e abuso. Sujeito a suas configurações, nós também usamos informações de contato para personalizar nossos serviços, habilitar certos recursos da conta (por exemplo, para verificação de acesso), para enviar informações sobre nossos serviços e personaliza-los, incluindo anúncios. O ZippZapp também usa suas informações de contato para direcionar marketing a você conforme as leis do país permitem, e para ajudar outras pessoas a encontrar sua conta, se suas configurações permitirem. Você poderá utilizar suas configurações de notificações por e-mail para controlar as notificações que receber do ZippZapp. Você também poderá descontinuar o recebimento de uma notificação seguindo as instruções contidas na notificação.
          </p>
        <li><strong class="pt-2 pb-4">
            Links
          </strong><br><br></li>
          <p>
            Para operar nossos serviços, acompanhamos como você interage com os links em nossos serviços. Isso inclui links em e-mails que enviamos a você e links em notificações que aparecem dentro do aplicativo, etc.
          </p>
          <p>
            Se você clicar em um link ou anúncio externo em nossos serviços, esse anunciante ou operador de site poderá inferir que você veio do ZippZapp, além de outras informações associadas ao anúncio em que você clicou, como características do público destinado a ser alcançado. Eles também podem coletar de você outros dados pessoais, como identificadores de cookies ou seu endereço IP.
          </p>
        <li> <strong class="pt-2 pb-4">
            Cookies
          </strong><br><br></li>
          <p>
            Um cookie é um pequeno arquivo de dados que é armazenado em seu computador ou dispositivo móvel. Como muitos sites, usamos cookies e tecnologias semelhantes para coletar dados adicionais de uso do site e para operar nossos serviços. Os cookies não são requeridos para muitas partes de nossos serviços, como buscar e consultar perfis públicos. Embora a maioria dos navegadores da Web aceite cookies automaticamente, as configurações de muitos navegadores podem ser modificadas para recusar cookies ou alertá-lo quando um site estiver tentando inserir um cookie em seu computador. No entanto, é possível que alguns de nossos Serviços não funcionem adequadamente se você desativar os cookies. Quando seu navegador ou dispositivo permitir, nós usaremos cookies de sessão e cookies persistentes para entender melhor como você interage com nossos serviços, monitorar padrões de uso agregados, personalizar e operar nossos serviços, fornecer segurança de conta, personalizar o conteúdo que mostramos a você, inclusive anúncios, e lembrar suas preferências de idioma. Nós não oferecemos suporte à opção de navegador Não Rastrear.
          </p>
        <li> <strong class="pt-2 pb-4">
            Dados de Registro
          </strong><br><br></li>

        <p>
          Nós recebemos informações quando você visualiza conteúdo em ou interage com nossos serviços, o que nós chamamos de “Dados de Registro”, mesmo se não tiver criado uma conta. Por exemplo, quando você visita os nossos sites, acessa os nossos serviços, interage com as nossas notificações por e-mail, utiliza a sua conta para se autenticar em um site, aplicativo ou serviço de terceiro, ou quando visita um site, aplicativo ou serviço de terceiro que inclua conteúdo do ZippZapp, podemos receber informações sobre você. Esses Dados de Registro podem incluir informações como o seu endereço IP, tipo de navegador, sistema operacional, página da Web de origem, páginas da Web visitadas, localização, sua operadora de telefonia celular, informações de dispositivo (inclusive Ids de dispositivo e aplicativos), termos de pesquisa (incluindo aqueles que não foram enviados como consultas) e informações de cookies. Também recebemos Dados de Registro quando você clica, visualiza ou interage com links em nossos serviços, inclusive quando você instala outro aplicativo por meio do ZippZapp. Usamos os Dados de Registro para operar nossos serviços e assegurar seu desempenho seguro, confiável e robusto. Por exemplo, usamos os Dados de Registro para proteger a segurança das contas e para determinar qual conteúdo é popular em nossos serviços. Também usamos esses dados para melhorar o conteúdo que mostramos a você, inclusive anúncios para melhorar a eficácia de nosso próprio marketing.
        </p>
        <p>
          Usamos as informações que você nos fornece e os dados que recebemos, inclusive Dados de Registro e dados de terceiros, para fazer inferências sobre quais tópicos você pode estar interessado, sua idade e os idiomas que você fala. Isso nos ajuda a promover e desenhar melhor nossos serviços para você e a personalizar o conteúdo que mostramos, inclusive anúncios.
        </p>
      <li><strong class="pt-2 pb-4">
          Anunciantes e Outros Parceiros de Publicidade
        </strong><br><br></li>
        <p>
          A receita de publicidade nos permite prestar suporte e melhorar nossos serviços. Nós podemos utilizar as informações descritas nesta Política de Privacidade para ajudar a tornar nossa publicidade mais relevante para você, para medir sua eficácia e para ajudar a reconhecer seus dispositivos para podermos fornecer anúncios a você. Nossos parceiros de publicidade e afiliadas compartilham conosco informações como ID do cookie do navegador, ID do dispositivo móvel ou hash criptográfico de um endereço de e-mail, assim como dados demográficos ou de interesse e conteúdo visualizado ou ações tomadas em um site ou aplicativo. Alguns de nossos parceiros de publicidade, especialmente nossos anunciantes, também nos permitem coletar informações similares diretamente de seu site ou aplicativo ao integrar nossa tecnologia de publicidade. As informações compartilhadas por parceiros e afiliados de anúncios ou coletadas pelo ZippZapp a partir de sites e aplicativos de parceiros e afiliados de anúncios podem ser combinadas com as outras informações que você compartilha com o ZippZapp e que o ZippZapp recebe sobre você descrito em outra parte da nossa Política de Privacidade.
        </p>
        <p>
          O ZippZapp adere aos Princípios de Autorregulamentação da Aliança de Publicidade Digital para Publicidade Comportamental On-line (também denominada “publicidade com base em interesses”) e respeita a ferramenta de escolha do consumidor da DAA (Digital Advertising Alliance [Aliança de Publicidade Digital]) para que você opte por não receber publicidade com base em interesses em https://optout.aboutads.info. Além disso, nossas políticas de anúncios também proíbem que anunciantes segmentem anúncios com base em categorias que considerarmos sensíveis ou proibidas pela legislação, como raça, religião, política, vida sexual ou saúde.
        </p>
      <li><strong class="pt-2 pb-4">
          Compartilhando seu controle
        </strong><br><br></li>
        <p>
          Nós compartilhamos ou divulgamos seus dados pessoais com o seu consentimento ou de acordo com as suas orientações, por exemplo, quando você autoriza que um cliente Web ou aplicativo de terceiros acesse a sua conta, ou quando você nos orienta a compartilhar seu feedback com uma empresa. De modo semelhante, para aprimorar sua experiência, trabalhamos como parceiros terceiros para que eles exibam seus conteúdos de vídeo no ZippZapp. Quando você assiste ou interage de outra forma com o conteúdo desses parceiros, eles podem receber ou tratar seus dados pessoais, conforme descrito em suas políticas de privacidade. Se você não quer que esse conteúdo seja reproduzido automaticamente, você pode ajustar suas configurações de reprodução automática.
        </p>

        <p>
          Sujeito às suas configurações, também fornecemos a terceiros determinados dados pessoais para nos ajudar a oferecer ou operar nossos serviços.
        </p>
      <li><strong class="pt-2 pb-4">
          Prestadores de Serviços
        </strong><br><br></li>
        <p>
          Nós engajamos com prestadores de serviços para desempenhar funções e prestar serviços para nós. Por exemplo, nós usamos uma variedade de serviços de terceiros para nos ajudar a operar nossos serviços, como hospedagem de nossos diversos vídeos, assim como para nos ajudar a compreender melhor a utilização dos nossos Serviços, como o Google Analytics. Nós podemos compartilhar seus dados pessoais privados com esses prestadores de serviços, sujeito a obrigações consistentes com esta Política de Privacidade e quaisquer outras medidas de confidencialidade e segurança adequadas, e sob a condição de que os terceiros utilizem seus dados pessoais privados somente em nosso nome e de acordo com as nossas instruções (os prestadores de serviços podem usar outros dados não pessoais para seu próprio benefício). Nós compartilhamos suas informações de pagamento com os prestadores de serviços de pagamento para processar pagamentos, prevenir, detectar e investigar fraudes ou outras atividades proibidas, facilitar a resolução de disputas como estornos ou reembolsos, e para outros objetivos associados à aceitação de cartões de crédito e débito.
        </p>
          <li><strong class="pt-2 pb-4">
          Legislação, Dado e Interesse Público
        </strong><br><br></li>

        <p>
          Não obstante qualquer disposição em contrário nesta Política de Privacidade ou nos controles que possamos oferecer a você, poderemos preservar, usar, compartilhar ou divulgar seus dados pessoais ou outros dados de segurança se acreditarmos que é razoavelmente necessário para o cumprimento de uma lei, regulamentação, processo legal ou solicitação governamental; para proteger a segurança de qualquer pessoa; para proteger a segurança ou integridade de nossa plataforma, inclusive para ajudar a prevenir spam, abuso ou agentes nocivos em nossos serviços, ou para explicar por que removemos conteúdo ou contas de nossos serviços; para endereçar questões de fraude, segurança ou técnicas; ou para proteger nossos direitos ou propriedade ou os direitos ou propriedade daqueles que usam nossos serviços. No entanto, nada nesta Política de Privacidade tem por objetivo limitar quaisquer direitos de defesa ou oposição que você possa ter em relação a solicitações de terceiros para a divulgação de seus dados pessoais, inclusive solicitações feitas por um governo.
        </p>
      <li><strong class="pt-2 pb-4">
          Afiliadas e Transferência de Propriedade
        </strong><br><br></li>
        <p>
          Se estivermos envolvidos em um processo de falência, fusão, aquisição, reorganização ou de venda de ativos, seus dados pessoais poderão ser vendidos ou transferidos como parte dessa transação. Esta Política de Privacidade será aplicável aos seus dados pessoais conforme sejam transferidas à nova entidade. Também podemos divulgar dados pessoais sobre você às nossas empresas afiliadas para ajudar a operar nossos serviços e os serviços de nossas afiliadas, inclusive a veiculação de anúncios.
        </p>
       <li> <strong class="pt-2 pb-4">
          Informações não pessoais
        </strong><br><br></li>
        <p>
          Nós compartilhamos ou divulgamos dados não pessoais, por exemplo, inagregadas como o número total de vezes em que as pessoas se envolveram com um Vídeo ou Post do Fórum, demografia, o número de usuários que clicaram em um link específico ou votaram em uma enquete (mesmo que apenas uma pessoa o tenha feito), os tópicos sobre os quais as pessoas estão se envolvendo em um local específico, alguns interesses inferidos ou relatórios aos anunciantes sobre quantas pessoas viram ou clicaram em seus anúncios.
        </p>
      </ul> -->
        <div class="col-12 mb-2">
          <a routerLink="/login">&#60; VOLTAR</a>
        </div>
      </div>
    </div>
  </div>
</div>
